import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Dropdown } from 'antd';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { isLoggedInState, userState } from '../atom';
import { PoweroffOutlined } from '@ant-design/icons';

const Navbar = (props) => {

  const goSignin = () => {
    document.location.href = '/Signin';
  };

  const isLogin = useRecoilValue(isLoggedInState);
  const user = useRecoilValue(userState);
  const resetUser = useResetRecoilState(userState);

  const logout = async () => {
    try {
      // await axios.post("http://api주소/auth/logout");
      // 로컬 스토리지에서 토큰 제거
      localStorage.removeItem('token');
      resetUser();
      goSignin();
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };


  const items = [
    {
      label: <a href="/">Home</a>,
      key: '0'
    },
    {
      label: <a href="/courses">Courses</a>,
      key: '1'
    },
    {
      label: isLogin && user.email ? <Button onClick={logout}>Sign Out</Button> : <a href="/Signin">Sign In</a>,
      key: '3'
    }
  ];

  return (
    <div className="container-fluid nav_bg">
      <div className="row">
        <div className="col-10 mx-auto">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">

              <NavLink className="navbar-brand" to="/">
                DSS Academy
              </NavLink>

              <Dropdown
                menu={{
                  items
                }}
                trigger={['click']}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
              </Dropdown>

              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav mb-2 mb-lg-0 ml-auto">
                  <li className="nav-item">
                    <NavLink exact className="nav-link" to="/" activeClassName="menu_active">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/courses" activeClassName="menu_active">
                      Courses
                    </NavLink>
                  </li>
                  {!(
                    isLogin && user.email
                  ) && <li className="nav-item">
                    <NavLink className="nav-link" to="/signin" activeClassName="menu_active">
                      Sign in
                    </NavLink>
                  </li>
                  }
                </ul>
              </div>
              {(
                isLogin && user.email
              ) && <div className={'logout-box'}>

                <Button
                  type="primary"
                  icon={<PoweroffOutlined />}
                  onClick={() => logout()}
                >
                  Sign out
                </Button>
              </div>}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
