import axios from 'axios';

const realms = 'DSS';
const apiDSSUrl = '//service.academy.dss.divinetech.dev:15151';
const apiBaseDevUrl = '//api.academy.dss.divinetech.dev:3000';
let apiBaseUrl = '//api.academy.dss.divinetech.dev';

const isDev = process.env.NODE_ENV === 'development';
if (isDev) {
  apiBaseUrl = apiBaseDevUrl;
}


export const getLectures = async () => {

  try {
    const response = await axios.get(`${apiBaseUrl}/public/courses`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error Lecture List', error);
    throw error;
  }
};

export const getLecturesInfo = async (token, id) => {

  try {
    const response = await axios.post(`${apiBaseUrl}/course`, { data: { id } }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error Lecture List', error);
    throw error;
  }
};

export const getUserInfo = async (token) => {
  try {
    const response = await axios.get(
      `https://auth.dss.divinetech.dev/realms/${realms}/protocol/openid-connect/userinfo`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    return response.data;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

export const getToken = async (user) => {
  const data = {
    'grant_type': 'password',
    'client_id': 'WebPortal',
    'client_secret': 'uoRVyju4bkDaRk99ZECbLiIsKELuqAxV',
    'username': user.email,
    'password': user.password,
    'scope': 'openid group profile email'
  };

  try {
    const response = await axios.post(`https://auth.dss.divinetech.dev/realms/${realms}/protocol/openid-connect/token`,
      new URLSearchParams(data), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        withCredentials: true
      });
    return response.data;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

export const chgPassword = async (token, data) => {
  try {
    const response = await axios.put(`https://auth.dss.divinetech.dev/realms/${realms}/account/credentials/password`,
      new URLSearchParams(data), {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    console.log('Token:', response.data);
    return response.data;
  } catch (error) {
    console.error('Change the password Error', error);
    throw error;
  }
};

export const saveVideoPlayLog = async (data) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/video/logs`, {
      data: {
        youtube_key: data.youtubeKey,
        lecture_id: data.lectureId,
        user_id: data.userId,
        user_type: data.userType,
        user_group: data.userGroup,
        video_current_time: data.videoCurrentTime,
        video_type: data.videoType
      }
    });
    return response.data;
  } catch (error) {
    console.log('Error save video logs : {}', data, error);
  }
};

export const dssOpenFolder = async (token, lectureId) => {
  try {
    const response = await axios.get(`${apiDSSUrl}/open/${String(lectureId)}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

export const dssResetFolder = async (token, lectureId) => {
  try {
    const response = await axios.get(`${apiDSSUrl}/recovery/${String(lectureId)}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

export const DSSStartService = async (token, scenario) => {
  try {
    const response = await axios.get(`${apiDSSUrl}/DSSAcademyExec/${String(scenario)}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

export const DSSStopService = async (token) => {
  try {
    const response = await axios.get(`${apiDSSUrl}/DSSAcademyStop`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};