import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const sessionStorage =
  typeof window !== 'undefined' ? window.sessionStorage : undefined;

const { persistAtom } = recoilPersist({
  key: 'NavigationAtom',
  storage: sessionStorage
});

export const isLoggedInState = atom({
  key: 'isLoggedIn',
  default: !!localStorage.getItem('token')
});

export const userState = atom({
  key: 'user',
  default: {
    name: '',
    email: '',
    family_name: '',
    given_name: '',
    groups: [],
    preferred_username: ''
  },
  effects_UNSTABLE: [persistAtom]
});

