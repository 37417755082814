import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, message, Result } from 'antd';
import { SmileOutlined, TeamOutlined } from '@ant-design/icons';
import loginItemImg1 from '../images/login-item-1.png';
import loginItemImg2 from '../images/login-item-2.png';
import { getToken, getUserInfo } from '../api';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isLoggedInState, userState } from '../atom';


const SignIn = () => {

  // marx@divinetech.kr
  // AsDf1234@


  const history = useHistory();

  const goCourse = () => {
    history.push('/courses');
  };

  const setUser = useSetRecoilState(userState);
  const setIsLoggedIn = useSetRecoilState(isLoggedInState);
  const isLogin = useRecoilValue(isLoggedInState);
  const userInfo = useRecoilValue(userState);

  const [messageApi, contextHolder] = message.useMessage();

  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Input value is invalid.'
    });
  };

  const [user, setLocalInputUser] = useState({
    isSignedIn: false,
    name: '',
    email: '',
    password: '',
    photo: ''
  });

  const handleBlur = (e) => {
    let isFieldValid = true;
    if (e.target.name === 'email') {
      isFieldValid = /\S+@\S+\.\S+/.test(e.target.value);
    }
    if (e.target.name === 'password') {
      isFieldValid = e.target.value.length > 0;
    }
    if (isFieldValid) {
      const newUserInfo = { ...user };
      newUserInfo[e.target.name] = e.target.value;
      setLocalInputUser(newUserInfo);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.email && user.password) {

      getToken(user).then(token => {
        // 토큰을 가지고 원하는 작업 수행
        getUserInfo(token.access_token).then((userInfo) => {
          console.log(userInfo);

          localStorage.setItem('token', token.access_token); // 로컬 스토리지에 토큰 저장
          setUser({ ...userInfo });
          setIsLoggedIn(true);
          messageApi.open({
            type: 'loading',
            content: 'Login is Success.',
            duration: 0
          });
          setTimeout(messageApi.destroy, 2000);
          goCourse();
        });
      }).catch(error => {
        // 오류 처리
        console.log('login error');
        messageApi.open({
          type: 'error',
          content: 'Login is not permitted.'
        });
      });
      // }

    } else {
      error();
      return false;
    }
  };

  useEffect(() => {
    const goCourse = () => {
      history.push('/courses');
    };
    if (isLogin && userInfo.email) goCourse();
  }, [isLogin, userInfo, history]);


  return (
    <div className="container-fluid login-section">
      {contextHolder}
      <div className="row justify-content-md-center">
        <div className="col-md-3">
          <div className="text-center py-5 login-box">

            <img alt="Login Desktop 2" src={String(loginItemImg1)} className={'img-laptop-1'} />
            <img alt="Login Desktop 1" src={String(loginItemImg2)} className={'img-laptop-2'} />

            <Result
              icon={<SmileOutlined />}
              title="발급받은 아이디 와 비밀번호를 입력해 주세요."
            />

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="email"
                className="form-control py-1"
                onBlur={handleBlur}
                placeholder="Email"
                required
              />
              <br />
              <input
                type="password"
                name="password"
                className="form-control py-1"
                onBlur={handleBlur}
                placeholder="Password"
                required
              />
              <br />
              <Button type="primary" htmlType="submit" icon={<TeamOutlined />} size={'large'} className={'btn-login'}>
                Sign in
              </Button>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
