import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import './style.scss';
import About from './components/About';
import Courses from './components/Courses';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SignIn from './components/SignIn';
import View from './components/View';


const App = () => {


  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/courses">
          <Courses />
        </Route>
        <Route exact path="/courses/:id"
               render={(props) => <View />} />
        <Route exact path="/signin">
          <SignIn />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Redirect to="/">
          <Home />
        </Redirect>
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
