import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, message, Tag, Tooltip } from 'antd';
import { useRecoilValue } from 'recoil';
import { isLoggedInState, userState } from '../atom';
import Meta from 'antd/es/card/Meta';
import { useHistory } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';
import { getLectures } from '../api';
import fallbackImage from '../assets/course/title/empty-title.png';

const Courses = () => {

  const history = useHistory();

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState('');
  const [list, setList] = useState([]);

  const isLogin = useRecoilValue(isLoggedInState);
  const user = useRecoilValue(userState);

  const isProfessor = user?.groups.some(item => item.toUpperCase().includes('PROFESSOR'));

  const ImageWithFallback = ({
    src,
    fallback,
    alt,
    ...props
  }) => {

    const handleError = (event) => {
      event.target.src = fallbackImage;
    };

    const imagePath = `${process.env.PUBLIC_URL}/course/title/${src}`;
    return <img className="card-img-top" src={imagePath} onError={handleError} alt={alt} {...props} />;
  };

  const modalPwdChg = () => {
    window.open(
      'https://auth.dss.divinetech.dev/realms/DSS/protocol/openid-connect/auth?response_type=code&client_id=WebPortal&redirect_uri=https://academy.dss.divinetech.dev&kc_action=update_password');
  };

  const startLearning = (e, key) => {
    e.stopPropagation();
    if (isLogin && user.email) {
      history.push(`/courses/${key}`);
    } else {
      messageApi.open({
        type: 'error',
        content: 'Signin is required.'
      }).then();
    }
  };

  useEffect(() => {
    if (isLogin && user.email) {
      setLoading(false);
      setMsg(`"${user.preferred_username}" 님 방문을 환영합니다.`);
      if (isProfessor) {
        setMsg(`"${user.preferred_username}" 교수님 방문을 환영합니다.`);
      }
    }
  }, [user, isLogin, isProfessor]);

  useEffect(() => {
    const getLectureList = async () => {
      const result = await getLectures();
      if (result.status === 200) {
        setList(result.data);
      }
    };

    getLectureList();

  }, []);


  return (
    <>
      {contextHolder}
      <div className="my-5">
        <h1 className="text-center text-primary h-title">Our Courses</h1>
      </div>
      {(
        isLogin && user.email
      ) && <div className="my-5 profile-card-container">
        <div className={'profile-card'}>
          <Card className={'profile-card-inner'} loading={loading}>
            <Meta
              title="😃 Welome to DSS Academy"
              description={msg}
            />
            <Tooltip title="Change the password.">
              <Button className={'btn-chg-pwd'} shape="circle" onClick={() => modalPwdChg()}
                      icon={<SettingOutlined />} />
            </Tooltip>
          </Card>
        </div>
      </div>}
      <div className="container-fluid mb-5 lecture-list">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row gy-4">
              {list.map(courses => <div className="col-md-3 col-10 mx-auto" key={courses.id}
                                        onClick={(e) => startLearning(e, courses.id)}>
                <div className="card lec-card">
                  {courses.course_kind === '이론' && <Tag color="processing">이론</Tag>}
                  {courses.course_kind !== '이론' && <Tag color="success">실습</Tag>}
                  <ImageWithFallback
                    src={`${String(courses.id).trim()}-title.png`}
                    fallback={fallbackImage}
                    alt="Course Title Image"
                  />

                  <div className="card-body">
                    <Tooltip title={courses.course_title} color={'#3658bc'} key={'#ffffff'}>
                      <h5 className="card-title">
                        <FontAwesomeIcon icon={faUniversity} /> {courses.course_title}
                      </h5>
                    </Tooltip>
                    <button className="btn btn-primary mt-3" onClick={(e) => startLearning(e, courses.id)}>
                      Start Learning
                    </button>
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;