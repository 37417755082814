import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSvg } from '../images/Logo-Academy.svg';
import rocketImg from '../images/rocket.png';
import trophyImg from '../images/trophy.png';
import { Button } from 'antd';

const Common = (props) => {
  const downloadDssService = (type = 'windows') => {
    window.open('https://cdn.datas.divinetech.dev/academy/DSSService.exe');
    // api.open({
    //   message: 'Download the DSS Service for windows',
    //   description: 'The service is temporarily preparing. We will make it available for download soon.',
    //   icon: (
    //     <SmileOutlined
    //       style={{
    //         color: '#108ee9'
    //       }}
    //     />
    //   )
    // });
  };
  return (
    <section id="header" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                <div className={'main-logo-box'}>
                  <LogoSvg />
                </div>
                <h1>
                  {props.name}
                  <strong className="brand-name"> DSS Academy</strong>{' '}
                </h1>

                <br />

                <h2 className="txt-desc">
                  We offer online courses specialized in autonomous driving.
                </h2>
                <h2 className="txt-desc">
                  자율주행에 특화된 온라인 교육 과정을 제공합니다.
                </h2>

                <div className="mt-3">
                  <Link to={props.visit} className="btn-get-started">
                    {props.btname}
                  </Link>
                </div>

                <div className="mt-3">
                  <h1 className={'download-title'}>⬇️ Download DSS Service</h1>
                  <Button.Group size={'large'} className={'download-btn-group'}>
                    <Button type="primary" onClick={() => downloadDssService('windows')}>
                      <img src={require('../assets/images/microsoft.png')} alt="microsoft" />
                      for Windows
                    </Button>
                    <Button type="primary" disabled={true}>
                      <img src={require('../assets/images/apple.png')} alt="apple" />
                      for Mac
                    </Button>
                    <Button type="primary" disabled={true}>
                      <img src={require('../assets/images/linux.png')} alt="linux" />
                      for Linux
                    </Button>
                  </Button.Group>
                </div>
              </div>

              <div className="col-md-6 order-1 order-lg-2 header-img">
                <img alt="Trophy" src={String(trophyImg)} className={'img-trophy'} />
                <img alt="Rocket" src={String(rocketImg)} className={'img-rocket'} />
                <img src={props.imgsrc} alt="headerImg" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Common;
