import React from 'react';

const Footer = () => {
  return (
    <footer className="footer w-100 text-center my-1">
      <small> All Rights Reserved &copy; 2024 by Divine Technology.</small>
      <br />
      <small>사업자등록번호 : 598-81-01499 (대표자 : 이용채)</small>
      <br />
      <small> All content on this site is legally protected. </small>
    </footer>
  );
};

export default Footer;