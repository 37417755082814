import React, { Suspense, useEffect, useState } from 'react';
import { Button, Card, message, Modal } from 'antd';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useHistory, useParams } from 'react-router-dom';
import { isLoggedInState, userState } from '../atom';
import Meta from 'antd/es/card/Meta';
import { getLecturesInfo } from '../api';
import { ArrowUpOutlined } from '@ant-design/icons';

const preloadComponent = (componentName) => {
  switch (String(componentName).trim()) {
    case '11':
      return import('./lecture/Lecture11');
    case '21':
      return import('./lecture/Lecture21');
    case '31':
      return import('./lecture/Lecture31');
    case '32':
      return import('./lecture/Lecture32');
    case '41':
      return import('./lecture/Lecture41');
    case '42':
      return import('./lecture/Lecture42');
    case '51':
      return import('./lecture/Lecture51');
    case '52':
      return import('./lecture/Lecture52');
    case '61':
      return import('./lecture/Lecture61');
    case '62':
      return import('./lecture/Lecture62');
    case '71':
      return import('./lecture/Lecture71');
    case '72':
      return import('./lecture/Lecture72');
    case '81':
      return import('./lecture/Lecture81');
    case '82':
      return import('./lecture/Lecture82');
    case '91':
      return import('./lecture/Lecture91');
    case '92':
      return import('./lecture/Lecture92');
    case '101':
      return import('./lecture/Lecture101');
    case '102':
      return import('./lecture/Lecture102');
    case '111':
      return import('./lecture/Lecture111');
    case '112':
      return import('./lecture/Lecture112');
    default:
      document.location.href = '/courses';
      throw new Error('Unknown component');
  }
};

const View = () => {

  const resetUser = useResetRecoilState(userState);

  const [messageApi, contextHolder] = message.useMessage();

  const { id: lectureId } = useParams();

  const [Component, setComponent] = useState(null);

  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState('');

  const isLogin = useRecoilValue(isLoggedInState);
  const user = useRecoilValue(userState);

  // const isStudent = user?.groups.some(item => item.toUpperCase().includes('STUDENT'));
  const isProfessor = user?.groups.some(item => item.toUpperCase().includes('PROFESSOR'));

  const history = useHistory();

  const goCourse = () => {
    history.push('/courses');
  };

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });

  };

  useEffect(() => {
    if (isLogin && user.email) {
      setLoading(false);
      setMsg(`"${user.preferred_username}" 님, 강의에 입장하셨습니다.`);
      if (isProfessor) {
        setMsg(`"${user.preferred_username}" 교수님, 강의에 입장하셨습니다.`);
      }
    }
  }, [user, isLogin, isProfessor]);

  useEffect(() => {

    if (lectureId) {
      const loadComponent = async () => {
        try {
          const lectureUniqId = String(lectureId).replace('-', '').trim();
          const { default: LoadedComponent } = await preloadComponent(lectureUniqId);
          setComponent(() => LoadedComponent);

          const token = localStorage.getItem('token');

          try {
            const result = await getLecturesInfo(token, lectureId);

            if (result.status === 200) {
              console.log('Token 정상 인증');
            }
          } catch (e) {

            const { response } = e;
            if (response.status === 401) {
              Modal.info({
                title: 'Session is Expired.',
                content: '다시 로그인 해주세요.',
                onOk() {
                  localStorage.removeItem('token');
                  resetUser();
                  document.location.href = '/Signin';
                }
              });
            }
          }


        } catch (error) {
          console.error(`Error loading component: ${lectureId}`, error);
        }
      };
      loadComponent();
    }
  }, [lectureId, Component, messageApi, resetUser]);

  useEffect(() => {
    const goCourse = () => {
      history.push('/courses');
    };
    if (!isLogin && !user.email) goCourse();
  }, [isLogin, user, history]);

  return (
    <>
      {contextHolder}
      {isLogin && <div className="my-5 profile-card-container">
        <div className={'profile-card'}>
          <Card className={'profile-card-inner'} loading={loading}>
            <Meta
              title="⏱️ 강의 시작이 기록 되었습니다."
              description={msg}
            />
            <Button className={'btn-back'} onClick={() => goCourse()}>Courses List</Button>
          </Card>
        </div>
      </div>}
      <Suspense fallback={<div>Loading...</div>}>
        {Component && <Component />}
      </Suspense>

      <div className="btn-top-area">
        <Button shape="circle" onClick={scrollToTop} icon={<ArrowUpOutlined />} />
      </div>

    </>
  );
};

export default View;